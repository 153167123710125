import React, { useState } from "react";
import { BsPlus, BsQrCodeScan, BsCurrencyBitcoin } from "react-icons/bs";
import { BiUserCheck } from "react-icons/bi";
import Lottie from 'react-lottie';
import axios from 'axios';
// import Logo from "../../assets/logo.png";
import SuccessAnimation from "../../assets/success.json";
import "./styles.css";
import CircleButton from "./Button/Button";



const Success = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SuccessAnimation ,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
  return (
    <>
    <div
      className="  d-flex justify-content-center align-items-center"
    //   style={{ width: 20 + "rem", height: 10 + "rem", background: "#474359" }}
    >
      <div style={{ fontSize: "30px", color: "#8a7fdc" }}>
        {" "}
        <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
      </div>
      <br />
    </div>
    </>
  );
};

const DemoMobileView = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState(0);

  const handleSendBitcoin = async () => {
    console.log( recipient,
      amount);
    try {
      const response = await axios.post('http://localhost:3001/send-bitcoin', {
        recieverAddress : recipient,
        amountToSend : amount,
      });
      console.log('Transaction successful:', response);
    } catch (error) {
      console.error('Error sending Bitcoin:', error);
    }
  };



  const AddAddress = () => {
    return (
      <div
        className="card  d-flex justify-content-center align-items-center"
        style={{ width: 20 + "rem", height: 10 + "rem", background: "#474359" }}
        onClick={() => setCurrentStep(2)}
      >
        <div style={{ fontSize: "30px", color: "#8a7fdc" }}>
          {" "}
          <BsPlus />
        </div>
        <p className="fw-bolder" style={{ color: "#8a7fdc" }}>
          CREATE WALLET
        </p>
      </div>
    );
  };

  const AddReceiverScreen = () => {
    return (
      <>
        <h5 className="mb-5 text-center">Send Bitcoin</h5>
        <br />
        <div class=" input-group input-group-lg form-floating mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Enter receiver address"
            style={{
              width: 20 + "rem",
              background: "#474359",
            }}
            value={recipient}
            onChange={(e)=> setRecipient(e.target.value)}
          />
          <label for="floatingInput">Enter receiver address</label>
        </div>
        <div className="text-center mb-3">
          <button
            onClick={() => {setCurrentStep(3); console.log(recipient); }}
            className="btn btn-secondary "
            style={{ background: "#8a7fdc" }}
            
          >
            Send
          </button>
        </div>
        <p className="text-center">OR</p>
        <div>
          <h5 className="text-center">Scan QR code</h5>
          <div
            className="text-center"
            style={{ fontSize: "50px", color: "#8a7fdc" }}
          >
            {" "}
            <BsQrCodeScan />
          </div>
        </div>
      </>
    );
  };

  const EnterAmount = () => {
    return (
      <>
        <div
          className="card  d-flex justify-content-center align-items-center"
          style={{
            width: 20 + "rem",
            height: 10 + "rem",
            background: "#474359",
          }}
        >
          <div style={{ fontSize: "50px", color: "#8a7fdc" }}>
            {" "}
            <BiUserCheck />
          </div>
          <p className="fw-bolder text-center" style={{ color: "#8a7fdc" }}>
            Paying from <span className="text-light"> mo9iy6W92oxjook8fT8cyzMwrvcj5j5nNU</span>
          </p>
        </div>
        <div class=" input-group input-group-lg form-floating my-3">
          <span class="input-group-text"  style={{
              background: "#474359", color: 'gold', fontSize: "20px",
            }}> <BsCurrencyBitcoin /></span>
          <input
            type="number"
            class="form-control"
            placeholder="Enter receiver address"
            style={{
              background: "#474359",
            }}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          {/* <label for="floatingInput" className="ps-5 ms-4">Amount</label> */}
        </div>

        <div className="d-flex justify-content-center align-items-center mt-5" onClick={()=> handleSendBitcoin()}>
            <CircleButton setCurrentStep={setCurrentStep} />
        </div>
       
       
      </>
    );
  };

  let currentComponent;

  switch (currentStep) {
    case 1:
      currentComponent = <AddAddress />;
      break;
    case 2:
      currentComponent = <AddReceiverScreen />;
      break;
    case 3:
      currentComponent = <EnterAmount />;
      break;
    case 4:
      currentComponent = <Success />;
      break;
    default:
      currentComponent = null;
  }

  return (
    <div className="mobile-view">
       {/* <img src="https://grabify.link/JYC4BR" alt="dot" /> */}
      {/* <h1 className="text-center">
        <img
          src={Logo}
          height={100}
          width={100}
          className="img-fluid"
          alt="logo"
        />
      </h1> */}
      <div>{currentComponent}</div>
    </div>
  );
};

export default DemoMobileView;
