const LongCard = ({
  image,
  heading,
  subOne,
  subTwo,
  subThree,
  isImageLeft,
}) => {
  return (
    <>
      <div className="card bg-black mb-3">
        <div className="row ">
          {isImageLeft ? (
            <>
              <div className="col-md-6 col-xs-12 d-flex justify-content-start align-items-center">
                <img src={image} className="img-fluid" alt="logo" />
              </div>
              <div className="col-md-6 col-xs-12 d-flex justify-content-center align-items-center">
                <div className="text-center">
                  <h1 className="text-center text-light fw-bolder my-5">
                    {heading}
                  </h1>
                  <h4 className="text-center text-light m-5">
                  {subOne} <br />
                    {subTwo} <br />
                    {subThree}
                  </h4>

               
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 col-xs-12 d-flex justify-content-center align-items-center">
                <div className="text-center">
                  <h1 className="text-center text-light fw-bolder my-5">
                    {heading}
                  </h1>

                  <h5 className="text-center fw-bolder m-4">
                    {subOne} <br />
                    {subTwo} <br />
                    {subThree}
                  </h5>
                </div>
              </div>
              <div className="col-md-6 col-xs-12 d-flex justify-content-center align-items-center">
                <img src={image} className="img-fluid" alt="logo" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LongCard;
