import { terms } from "../data/terms";
import Term from "../assets/terms.svg";
import Footer from "../components/Footer";

function Terms() {
  return (
    <div>
    <div className="bg-light">
      <div className="bg-black">
        <div className="row px-5">
          <div className="col-lg-6 col-xs-12 container pt-5 px-5">
            <h1 className="text-start fw-bolder ps-4 display-5 text-white">
              Terms of Use
            </h1>
            <p className="text-muted ps-4 pt-2">
              By using the Service, you agree to be bound by these Terms and Conditions.
            </p>
          </div>
          <div className=" col-lg-6 col-xs-12 container pt-5 px-5 text-center">
            <img src={Term} className="img-fluid" alt="Secure" />
          </div>
        </div>
      </div>
      <div className="p-5">
        {terms.map((item, i) => {
          return (
            <div className="text-justify" key={i}>
              <h3 className="text-dark fw-bold">{i + 1}.&nbsp;{item.title}</h3>
              {item.data.map((datas, i) => (
                <p className="text-muted pe-3" key={i}>{datas}</p>
              ))}
            </div>
          );
        })}
      </div>
    </div>
    <Footer  bg="black" color="white" />
    </div>
  );
}

export default Terms;