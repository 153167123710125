import React from "react";
import { Link } from "react-router-dom";


function Footer({bg, color, position}) {

    return (
        <div className={`bg-${bg} ${position} text-center`}>


            <div className={`pt-3 text-${bg}`}>
                <section className="mb-4">
                    <Link to='/terms' className={`text-decoration-none text-${color}  btn-floating m-1`}> Terms & Conditions</Link>
                    <div className={`vr bg-${color}`}></div>
                    <Link to='/privacy' className={`text-decoration-none text-${color}  btn-floating m-1`}>  Privacy Policy</Link>
                    <div className={`vr bg-${color}`}></div>
                    <Link to='/privacy' className={`text-decoration-none text-${color}  btn-floating m-1`}> Contact</Link>
                    <br />
                    <hr className={`line bg-${color}`} />
                    <Link to='/' className={`text-decoration-none text-${color}  btn-floating`}>
                        © 2023 DGX Ventures Pvt Ltd. All rights reserved.</Link>
                </section>
            </div>
        </div>

    );
}

export default Footer;




