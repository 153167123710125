import { useNavigate } from "react-router-dom";
import not from "../assets/404.svg";
function Not() {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section className="bg-black vh-100 d-flex justify-content-center align-items-center text-center">
      <div>
        <h1 className="text-white">Hmm. You seem lost!</h1>
        <p className="text-muted text-center">
          Let us help you on your way back.
        </p>

        <img src={not} alt="not" />
        <br />
        <p>Page is not available.</p>
        <div className="flexGrow">
          <button className="btn btn-primary" onClick={goBack}>
            Go Back
          </button>
        </div>
      </div>
    </section>
  );
}

export default Not;