import { privacy } from "../data/terms";
import Privacy from "../assets/privacy.svg";
import Footer from "../components/Footer";

function Terms() {
  return (
    <div>
      <div className="bg-light">
        <div className="bg-black">
          <div className="row px-5">
            <div className="col-lg-6 col-xs-12 container pt-5 px-5">
              <h1 className="text-start fw-bolder ps-4 display-5 text-white">
                Privacy Policy
              </h1>
              <p className="text-muted ps-4 pt-2">
                Last updated: April 25, 2023
                <br />
                This Privacy Policy describes Our policies and procedures on the collection, use and
                disclosure of Your information when You use the Service and tells You about Your
                privacy rights and how the law protects You.
                We use Your Personal data to provide and improve the Service. By using the
                Service, You agree to the collection and use of information in accordance with this
                Privacy Policy.
              </p>
            </div>
            <div className=" col-lg-6 col-xs-12 container pt-5 px-5 text-center">
              <img src={Privacy} className="img-fluid" alt="Secure" />
            </div>
          </div>
        </div>
        <div className="p-5">
          {privacy.map((item, i) => {
            return (
              <div className="text-justify" key={i}>
                <h3 className="text-dark fw-bold">{i + 1}.&nbsp;{item.title}</h3>
                {item.data.map((datas, i) => (
                  <p className="text-muted pe-3" key={i}>{datas}</p>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <Footer bg='black' color='light' />
    </div>
  );
}

export default Terms;