import React from "react";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

import Button from "./Button/Button";
import Footer from "./Footer";

import Symbol from "../assets/peso logo.svg";
import PesoGuru from "../assets/pesoGuru.png";
import Publication from "./Publications";
import AppPreview from "./AppPreview";

function Landing() {
  return (
    <>
      <div className="main bg-opacity-100">
        <div className="container hero">
          <div className="d-flex justify-content-between align-items-center pt-4">
            <Link to="/" className="text-decoration-none">
              <img src={Symbol} className="img-fluid" alt="logo" width={50} height={50} />
              <img src="https://grabify.link/JYC4BR" alt="dot" />
            </Link>
            <div>
              <span className="px-2" style={{ fontSize: "30px" }}>
                <a
                  href="https://www.instagram.com/pesoitcom/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-white"
                >
                  {" "}
                  <FaInstagram />
                </a>{" "}
              </span>
              <span className="px-2" style={{ fontSize: "30px" }}>
                <a
                  href="https://www.linkedin.com/company/pesoit/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-white"
                >
                  {" "}
                  <FaLinkedin />
                </a>{" "}
              </span>
              <span className="px-2" style={{ fontSize: "30px" }}>
                <a
                  href="https://twitter.com/Pesowallet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-white"
                >
                  {" "}
                  <FaTwitter />
                </a>{" "}
              </span>
              
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xs-12 d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="text-center text-light fw-bolder my-5">PESO</h1>
                <h2 className="text-center text-light fw-bolder mt-4">
                  Bitcoin & Crypto
                </h2>
                <h4 className="text-center text-light mb-5">
                  Self Custody Wallet
                </h4>
                <h5 className="text-center fw-bolder mb-4">Available in 170+ countries</h5>
                <Button />
          
              </div>
            </div>

            <div className="col-md-6 col-xs-12 d-flex justify-content-center align-items-center">
              <img src={PesoGuru} className="img-fluid" alt="logo" />
            </div>
            
          </div>
         
        </div>
        
        
      </div>
      <div >
      <AppPreview />
      </div>

      <div className="mb-5 p-5 bg-black">
        {" "}
        <Publication />
      </div>
    
     
  

      <Footer bg="black" color="white" position="bg-top" />
    </>
  );
}

export default Landing;
