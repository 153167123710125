import LongCard from "./LongCard";
import Wallet from "../../assets/preview/wallet.png";
import Two from "../../assets/preview/two.png";
import Three from "../../assets/preview/three.png";
import Four from "../../assets/preview/four.png";
import Five from "../../assets/preview/five.png";
import Six from "../../assets/preview/six.png";



const AppPreview = () => {
  return (
    <>
      <LongCard image={Wallet} isImageLeft={true} heading='Send & Receive' subOne='Pay anywhere in the world with' subTwo='no transaction & amount limit.' />
      <LongCard image={Two} heading='Ownership' subOne='You own your private keys.' subTwo='That means nobody can freeze,' subThree='access or halt your funds.' />
      <LongCard image={Three} isImageLeft={true} heading='Markets' subOne='Live Price movements,Financial charts' subTwo='& latest news.' />
      <LongCard image={Four} heading='Decentralised' subOne='Your private keys never' subTwo='leave your phone and is never' subThree='exposed to the internet.' />
      <LongCard image={Five} isImageLeft={true} heading='Cutting Edge' subOne='Expereince ultimate security and' subTwo='High Speed bitcoin transaction' />
      <LongCard image={Six} heading='Stay Ahead' subOne='Always 10 steps ahead of market.' subTwo='Latest info from deep internet.' subThree='Save it, share it or just PESOIT' />

    
    </>
  );
};

export default AppPreview;
