import Apn from "../../assets/pub/APNNews.svg";
import CeoInd from "../../assets/pub/CeoInd.svg";
import CeoUae from "../../assets/pub/CEOUAE.svg";
import dailyHunt from "../../assets/pub/dailyHunt.svg";
import EdTimes from "../../assets/pub/Edtimes.svg";
import GoogleNews from "../../assets/pub/GoogleNews.svg";
import NewsTrack from "../../assets/pub/NewsTrack.svg";

const PubCard = ({ logo, title, link }) => {
  return (
    <div
      class=" m-1 p-1  rounded-3"
      style={{ width: 250 + "px", height: 100 + "px" }}
      onClick={link}
    >
      <a
        className="text-decoration-none"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logo} class="card-img-top" alt={logo} />
      </a>
    </div>
  );
};

function Publication() {
  return (
    <div className="container">
      <p className="text-center text-white  fw-bolder ">FEATURED ON</p>
      <h3 className="text-center text-white  fw-bolder mb-5">
        PESO in the Media{" "}
      </h3>

      <div className="row text-center">
        <div className="col-lg-3 col-sm-6 align-self-center">
          <PubCard
            logo={dailyHunt}
            title="Web 3 application seamlessly merges fintech and blockchain"
            link="http://dhunt.in/PQyXD"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <PubCard
            logo={CeoInd}
            title="Peso App has successfully integrated its first pillar and is live"
            link="https://ceoindiaweekly.com/vijay-saran-and-utkarshini-arora-empowering-the-future-of-finance-through-the-peso-app/"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <PubCard
            logo={GoogleNews}
            title="Peso App featured on Google News after just few months of launch"
            link="https://www.instagram.com/p/CyFa1s5Bn1q/?igshid=MzRlODBiNWFlZA=="
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <PubCard
            logo={EdTimes}
            title="Changing the Financial Landscape: Vijay Saran & Utkarshini Arora’s Peso App"
            link="https://edtimes.in/changing-the-financial-landscape-vijay-saran-and-utkarshini-aroras-peso-app/"
          />
        </div>
      </div>
      {/* Row two */}
      <div className="row mt-2 mb-5 pb-5 text-center">
        <div className="col-lg-3 col-sm-6">
          <PubCard
            logo={Apn}
            title="Peso App: Revolutionizing the Future of FinTech with Blockchain"
            link="https://www.apnnews.com/peso-app-revolutionizing-the-future-of-fintech-with-blockchain/"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <PubCard
            logo={
              "https://www.cointrust.com/wp-content/themes/cointrust/images/logo.png"
            }
            title="Peso App is attracting global attention and anticipation"
            link="https://www.cointrust.com/market-news/indias-peso-app-revolutionizing-fintech-with-blockchain-integration"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <PubCard
            logo={NewsTrack}
            title="The Peso App is now available on Google Play and the App Store"
            link="https://english.newstracklive.com/ampnews/revolutionizing-finance-the-impact-of-vijay-saran-and-utkarshini-aroras-peso-app-sc71-nu318-ta318-1300417-1.html"
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <PubCard
            logo={CeoUae}
            title="The Peso App recieved global attention after releasing in UAE."
            link="https://ceoweeklyuae.com/vijay-saran-and-utkarshini-arora-empowering-the-future-of-finance-through-the-peso-app/"
          />
        </div>
      </div>
    </div>
  );
}

export default Publication;
