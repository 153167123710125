import React from 'react'
import DemoMobileView from '../components/Demo';

const Demo = () => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
           <DemoMobileView />
          
        </div>
    )
}

export default Demo;