import React from "react";

import AllRoutes from "./components/allRoutes";
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/common.css';
import './App.css'
import Footer from "./components/Footer";



function App() {
  return (
    <AllRoutes />
   
  );
}

export default App;




