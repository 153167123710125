import React from "react";
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import './button.css'
const Button = () => {
    return (
        <div class="container">
        <div class="btn"><a href="https://play.google.com/store/apps/details?id=com.pesoit&pli=1" target="_blank" className='text-decoration-none text-white'><FaGooglePlay /> &nbsp;Google Play</a></div>
        <div class="btn"><a href="https://apps.apple.com/us/app/peso/id6449457716" target="_blank" className='text-decoration-none text-white'> <FaApple /> App Store </a></div>
     
      </div>
    )
}

export default Button;
