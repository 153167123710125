import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Landing from "./Landing";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Demo from "../pages/Demo";
import Not from "../pages/Not";


function AllRoutes() {
  return (
 
<>
      <Router>
        <Routes>
          <Route element={<Landing />} path="/" />
          <Route element={<Terms />} path="/terms" />
          <Route element={<Privacy />} path="/privacy" />
          <Route element={<Demo />} path="/demo" />

          <Route element={<Not />} path="/*" />
   
        </Routes>
      </Router>
    
       </>
   
  );
}

export default AllRoutes;